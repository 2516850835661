import React from 'react';
import { Link } from 'gatsby';

import Layout from '../components/layout';
import Head from '../components/head';

const About = () => {
  return (
    <Layout>
      <Head title="About" />
      <h1>About</h1>
      <p>Talk about four week survey</p>
      <p>
        Data and photogrpahs collected by residents between date x and date y
      </p>
      <p>
        It is believed that more than 75% of the vehicles parked in Hollingdean
        at any one point belong to non-resident, non-visitors. Of these, a
        relatively small percentage are made up of vehicles that are being
        stored in the area, and remain unmoved from one month to the next. The
        rest are made up of commuters and non-residents.
      </p>
      <p>
        A significant majority of vhiecles passing through, and parking in,
        Hollingdean each day are unnecessary traffic attracted to the area by
        unrestricted parking
      </p>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam pharetra
        condimentum quam, ac mollis enim. Fusce a quam dolor.
      </p>
      <p>
        Aenean faucibus auctor molestie. Ut posuere nunc mauris, nec semper
        mauris pretium at. Curabitur enim mi, consequat vel tempus vel, luctus
        sed nisl.
      </p>
      <p>
        Sed erat ipsum, semper tincidunt porta sit amet, aliquam nec augue.
        Etiam vulputate ligula at bibendum elementum.
      </p>

      <p>
        All the extra traffic, congested, dangerous. People crawling as they
        enter Hollingdean, looking for a space to park in.
      </p>
      <p>The survey identified hundreds of vehicles...</p>
    </Layout>
  );
};

export default About;
